import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter, ROUTES } from '@angular/router';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { appRoutes } from './app.routes';
import { AppInterceptor } from './services/app.interceptor';
import { RouterResolverService } from './services/router-resolver.service';
import { SnapshotService } from './services/snapshot.service';
import { provideToastr, ToastrService } from 'ngx-toastr';
import { CustomToastrService } from './services/custom-toastr.service';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { ThemeModeService } from './services/theme-mode.service';
import { provideTransloco } from '@jsverse/transloco';
import { TranslocoHttpLoader } from './services/transloco-http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SwitchLanguageService } from './services/switch-language.service';
import { AuthApiService } from './api/auth-api.service';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { LayoutService } from './services/layout.service';

function appInitializer(
  snapshotService: SnapshotService,
  authApiService: AuthApiService,
) {
  return () => {
    return new Promise<void>((resolve) => {
      const token = authApiService.getAuthFromLocalStorage();
      if (!token) {
        resolve();
        return;
      }
      const getCurrentUserPromise = new Promise<void>((resolveCurrentUser) => {
        authApiService.getCurrentUser().subscribe((currentUser) => {
          snapshotService.isAuth.set(true);
          authApiService.currentUser.set(currentUser);
          resolveCurrentUser();
        });
      });
      Promise.all([getCurrentUserPromise]).then(() => {
        resolve();
      });
    });
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    ThemeModeService,
    RouterResolverService,
    SnapshotService,
    SwitchLanguageService,
    AuthApiService,
    NgbActiveOffcanvas,
    LayoutService,
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes),
    provideToastr(),
    provideHttpClient(withInterceptors([AppInterceptor])),
    importProvidersFrom([BrowserAnimationsModule]),
    provideEnvironmentNgxMask({
      validation: false,
    }),
    provideTransloco({
      config: {
        availableLangs: ['en', 'fr'],
        defaultLang: SwitchLanguageService.getStoredLanguage(),
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    {
      provide: ROUTES,
      useFactory: (resolver: RouterResolverService) => resolver.resolveRoutes(),
      deps: [RouterResolverService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [SnapshotService, AuthApiService],
    },
    {
      provide: CustomToastrService,
      useClass: CustomToastrService,
      deps: [ToastrService],
    },
  ],
};
