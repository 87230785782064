import {
  HttpErrorResponse,
  HttpHeaders,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';

import { catchError, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { inject } from '@angular/core';

export const AppInterceptor: HttpInterceptorFn = (req, next) => {
  const BASE_URL = environment.api;
  const toastrService = inject(ToastrService);

  const nextRequest = req.clone({
    headers: prepareHeaders(req),
    url: assignRequestURL(req),
  });

  return next(nextRequest).pipe(
    catchError((error: HttpErrorResponse) => {
      toastrService.error(error.error.title, 'Error');
      return throwError(() => error);
    }),
  );

  function assignRequestURL(request: HttpRequest<unknown>): string {
    if (request.url.includes('/assets/i18n/')) {
      return `${request.url}`;
    } else {
      return `${BASE_URL}${request.url}`;
    }
  }

  function prepareHeaders(request: HttpRequest<unknown>) {
    let headers: HttpHeaders = request.headers;

    if (!headers.get('no-auth')) {
      headers = headers.append('Authorization', 'Bearer ' + 'token');
    } else {
      headers = headers.delete('no-auth');
    }

    return headers;
  }
};
